table.dataTable.dtr-inline.collapsed tbody td:first-child,
table.dataTable.dtr-inline.collapsed tbody th:first-child {
    position: relative;
    padding-left: 30px;
    cursor: pointer;
}
table.dataTable.dtr-inline.collapsed tbody td:first-child:before,
table.dataTable.dtr-inline.collapsed tbody th:first-child:before {
    top: 8px;
    left: 4px;
    height: 16px;
    width: 16px;
    display: block;
    position: absolute;
    color: white;
    border: 2px solid white;
    border-radius: 16px;
    text-align: center;
    line-height: 14px;
    box-shadow: 0 0 3px #444;
    box-sizing: content-box;
    content: '+';
    background-color: #31b131;
}
table.dataTable.dtr-inline.collapsed tbody td:first-child.dataTables_empty:before,
table.dataTable.dtr-inline.collapsed tbody th:first-child.dataTables_empty:before {
    display: none;
}
table.dataTable.dtr-inline.collapsed tbody tr.parent td:first-child:before,
table.dataTable.dtr-inline.collapsed tbody tr.parent th:first-child:before {
    content: '-';
    background-color: #d33333;
}
table.dataTable.dtr-inline.collapsed tbody tr.child td:before {
    display: none;
}
table.dataTable.dtr-column tbody td.control,
table.dataTable.dtr-column tbody th.control {
    position: relative;
    cursor: pointer;
}
table.dataTable.dtr-column tbody td.control:before,
table.dataTable.dtr-column tbody th.control:before {
    top: 50%;
    left: 50%;
    height: 16px;
    width: 16px;
    margin-top: -10px;
    margin-left: -10px;
    display: block;
    position: absolute;
    color: white;
    border: 2px solid white;
    border-radius: 16px;
    text-align: center;
    line-height: 14px;
    box-shadow: 0 0 3px #444;
    box-sizing: content-box;
    content: '+';
    background-color: #31b131;
}
table.dataTable.dtr-column tbody tr.parent td.control:before,
table.dataTable.dtr-column tbody tr.parent th.control:before {
    content: '-';
    background-color: #d33333;
}
table.dataTable tr.child {
    padding: 0.5em 1em;
}
table.dataTable tr.child:hover {
    background: transparent !important;
}
table.dataTable tr.child ul {
    display: inline-block;
    list-style-type: none;
    margin: 0;
    padding: 0;
}
table.dataTable tr.child ul li {
    border-bottom: 1px solid #efefef;
    padding: 0.5em 0;
}
table.dataTable tr.child ul li:first-child {
    padding-top: 0;
}
table.dataTable tr.child ul li:last-child {
    border-bottom: none;
}
table.dataTable tr.child span.dtr-title {
    display: inline-block;
    min-width: 75px;
    font-weight: bold;
}
